<template >
  <h3>123</h3>
</template>

<script>
export default {
  name: "News",
  components : {},
  props: {

  },
  data() {
    return {}
  },

  created() {
    //init data FROM API
  },

  computed : {
    // calculate data from init data
  },
  watch : {
    // listening change data init

  },
  methods : {
    // handle even
  }
}
</script>

<style scoped>

</style>
